<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/hall/outlet-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="状态">
          <Select
            v-model="queryParam.description"
            :options="[
              {
                lable:'开门',
                value:'开门'
              },
              {
                lable:'关门',
                value:'关门'
              }
            ]"
            placeholder="请选择状态"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="识别日期">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>

      <div slot="table-item-avatarUrl" slot-scope="{ row }">
        <v-avatar color="grey lighten-2" rounded size="36">
          <img
            :src="
              row.avatarUrl
                ? `${$imgUrl}/${row.avatarUrl}`
                : require('@/assets/screen/hallAssistant/default_avatar.png')
            "
          />
        </v-avatar>
      </div>
      <div slot="table-item-updatedTime" slot-scope="{ row }">
        <span>{{ Moment(row.updatedTime).format('YYYY-MM-DD') }}</span>
      </div>
      <div slot="table-item-img" slot-scope="{ row }">
        <img style="width: 50px;height: 50px" v-if="row.attachList &&row.attachList.length "
             v-lazy='`${$imgUrl}${row.attachList && row.attachList[0]}`'
             alt="">
      </div>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
      >
        <div
          style="color: #25c4ff"
          @click="$refs.outletGateInfoDialog.openDialog({ row })"
        >
          查看
        </div>
      </div>
    </TableLayout>
    <outletGateInfoDialog
      ref="outletGateInfoDialog"
    ></outletGateInfoDialog>
  </Dialog>
</template>

<script>
import {mapState} from 'vuex';
import {assiginDeep} from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import Select from "@/views/screen/components/Select";
import DatePicker from '@/views/screen/components/DatePicker.vue';
import outletGateInfoDialog from './outletGateInfoDialog.vue';
import Moment from "moment";

export default {
  //组件注册
  components: {
    Select,
    Dialog,
    TableLayout,
    QueryLayout,
    SelectTree,
    DatePicker,
    outletGateInfoDialog,

  },
  //组件传值
  props: {},
  data() {
    return {
      Moment,
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '开关门记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '日期',
          value: 'updatedTime',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
        {
          text: '大门开关',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: '记录时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '状态',
          value: 'statusText',
          sortable: false,
          align: 'center',
        },
        {
          text: '截图',
          value: 'img',
          align: 'center',
          itemSlots: true,
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      customerType: (state) =>
        state.screenStore.commondict['CustomerTypeEnum']
          ? state.screenStore.commondict['CustomerTypeEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogOptions = {
          dialog: {
            width: 1500,
            title: '开关门记录',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
