<template>
  <div style="height: 100%">
    <div class="hallAssistant">
      <div class="hallAssistant_left">
        <LobbySituationLeft
          :global-query="globalQuery"
          :refresh-key="refreshKey"
          style="margin-bottom: 29px"
        ></LobbySituationLeft>
        <EarlyWarningLeft :refresh-key="refreshKey"></EarlyWarningLeft>
      </div>
      <div class="hallAssistant_center">
        <div class="img_center">
          <FloatBox>
            <template slot="two-line">
              <template v-for="code of ['01', '02']">
                <template v-if="getWarningTotal(code)">
                  <div
                    v-for="(e, i) in [getWarningTotal(code)]"
                    :key="i + code"
                    class="line-box"
                    style="cursor: pointer"
                  >
                    <span>识别{{ e.text }}人次：</span>
                    <span class="cyan-value" @click="numberClick(e.code, 1)">{{
                      formatString(e.count)
                    }}</span>
                  </div>
                </template>
              </template>
            </template>
            <template slot="one-line">
              <template v-for="code of ['03']">
                <template v-if="getWarningTotal(code)">
                  <div
                    v-for="(e, i) in [getWarningTotal(code)]"
                    :key="i + code"
                    class="line-box"
                    style="cursor: pointer"
                  >
                    <span>识别{{ e.text }}人次：</span>
                    <span class="cyan-value" @click="numberClick(e.code, 1)">{{
                      formatString(e.count)
                    }}</span>
                  </div>
                </template>
              </template>
            </template>
          </FloatBox>
        </div>
        <NumberChangeFooter
          :global-query="globalQuery"
          :refresh-key="refreshKey"
        ></NumberChangeFooter>
      </div>
      <div class="hallAssistant_right">
        <CustomerIdentificationRight
          :global-query="globalQuery"
          :refresh-key="refreshKey"
          style="margin-bottom: 29px"
        ></CustomerIdentificationRight>
        <PassengerFlowRight
          :global-query="globalQuery"
          :refresh-key="refreshKey"
        ></PassengerFlowRight>
      </div>
    </div>
    <CustomerIdentificationDialog
      ref="CustomerIdentificationDialog"
    ></CustomerIdentificationDialog>
    <outletGateDialog ref="outletGateDialog"></outletGateDialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { formatString } from '@/views/screen/config';
import CustomerIdentificationDialog from './modules/CustomerIdentificationDialog.vue';
import outletGateDialog from '@/views/screen/hallAssistant/modules/outletGateDialog';

export default {
  //组件注册
  components: {
    LobbySituationLeft: () => import('./modules/LobbySituationLeft.vue'),
    EarlyWarningLeft: () => import('./modules/EarlyWarningLeft.vue'),
    FloatBox: () =>
      import('@/views/screen/cashSupervision/components/FloatBox.vue'),
    NumberChangeFooter: () => import('./modules/NumberChangeFooter.vue'),
    CustomerIdentificationRight: () =>
      import('./modules/CustomerIdentificationRight.vue'),
    PassengerFlowRight: () => import('./modules/PassengerFlowRight.vue'),
    CustomerIdentificationDialog,
    outletGateDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
    }),
    getWarningTotal() {
      return (code) =>
        this.warningTotal.down &&
        this.warningTotal.down.find((e) => e.code == code);
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'getMonitordata',
    },
    refreshKey: {
      handler: 'getMonitordata',
    },
  },
  //DOM访问
  mounted() {
    this.$bus.$on('CustomerIdentificationDialog', (options) => {
      this.$refs.CustomerIdentificationDialog &&
        this.$refs.CustomerIdentificationDialog.openDialog(options);
    });
    this.$bus.$on('outletGateDialog', (options) => {
      this.$refs.outletGateDialog &&
        this.$refs.outletGateDialog.openDialog(options);
    });
    if (Object.keys(this.globalQuery).length) {
      this.getMonitordata();
    }
  },
  //保存方法
  methods: {
    numberClick(code, itemUuid) {
      const fixedParam = {
        searchViolationItemCodeList: code,
        // searchAnalysisItems: itemUuid || ''
      };
      if (!itemUuid) {
        fixedParam.violationFlag = '02';
      } else {
        fixedParam.violationFlag = '';
      }
      fixedParam.customerType = code;
      this.$bus.$emit('CustomerIdentificationDialog', {
        fixedParam,
      });
    },
    formatString,
    ...mapMutations(['screenStore/SET_warningTotal']),
    async getMonitordata() {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/hall/customer-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data)
        this['screenStore/SET_warningTotal'](data.data[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.hallAssistant {
  display: flex;
  height: 100%;

  .hallAssistant_left,
  .hallAssistant_right {
    flex: 0 0 26%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .layoutBox {
      flex: 1;
      overflow: hidden;
    }
  }

  .hallAssistant_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 38px;
    overflow: hidden;

    .img_center {
      flex: 1;
      overflow: hidden;
      background-image: url('../../../assets/screen/bj/hallAssistant_center.gif');
      background-position: bottom;
    }

    .layoutBox {
      flex: 0 0 245px;
      overflow: hidden;
    }
  }
}
</style>
